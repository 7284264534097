import locales from './Form.i18n';
import './Form.scss';

import CustomParams = Gtag.CustomParams;
import {
    IFormsValues,
    IProps,
    IState,
    MethodDeliveryOption,
    PreferWorkingDaysOption,
    PreferWorkingHoursOption,
    Screens,
} from './Form.types';
import {
    isDateCorrect,
    isFieldValid,
    isFormValid,
} from './Form.helpers'
import {
    FormInstance,
} from 'antd/lib/form';

import {
    LeftOutlined,
} from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Form,
    Image,
    Input,
    Radio,
    Result,
    Select,
    Space,
    Typography,
} from 'antd';
import {
    ButtonProps,
} from 'antd/lib/button/button';
import * as b_ from 'b_';
import React, {
    createRef,
} from 'react';
import {
    Link
} from 'react-router-dom';
// webview in Android have no window.localStorage
import {
    get as localStorageGet,
    set as localStorageSet,
} from 'local-storage';

import i18n from 'src/libs/i18n';
import {
    isWebView,
} from 'src/libs/detect'
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';

import ReactCodeInput from 'react-verification-code-input';
import InputNumber from 'src/components/InputNumber/InputNumber';
import PrivacyPolicy from 'src/components/PrivacyPolicy/PrivacyPolicy';

import {
    CPA_URL,
    CPA_KEY,
    CPA_VALUE,
    DEFAULT_CITY_ID,
    MAIN_SITE_URL,
    PRIVACY_POLICY_ROUTE,
    REFERRAL_KEY,
    USER_ID_KEY,
    AnalyticEvents,
    AddUserIdInEvent,
} from 'src/config';
import {
    __ as __dataSources,
    ICountry,
    IFormResponse,
    createApplication,
    getCountries,
    updateApplication,
    updatePassport,
    verifyPhone,
    FormValidation,
    FormError,
} from 'src/dataSources';
import {
    sendGoogle,
} from 'src/libs/googleAnalytics';
import {
    sendYandex,
} from 'src/libs/yandexMetrika'

const b = b_.with('form-controller');
const __ = i18n(locales);

const COUNTDOWN_DEFAULT_VALUE_SEC = 60;
const COUNTDOWN_TICK_MS = 1000;
const GO_TO_APP_URL = 'about:blank';
const PHONE_NUMBER_PREFIX = '+';
const BIRTH_DAY_INPUT_MAX_LENGTH = 2;
const BIRTH_MONTH_INPUT_MAX_LENGTH = 2;
const BIRTH_YEAR_INPUT_MAX_LENGTH = 4;
const PHONE_NUMBER_INPUT_MAX_LENGTH = 14;

const DEMO_ENABLED = (new URLSearchParams(window.location.search)).has('demo');

export default class FormController extends React.Component<IProps, IState> {

    state = {
        countdown: COUNTDOWN_DEFAULT_VALUE_SEC,
        currentScreen: Screens.PersonalData,
        formsValues: {
            token: '',
            user_ip: '',
            visible: false,
            captcha_key: 0 ,
            first_name: '',
            last_name: '',
            phone_number: '',
            city_id: DEFAULT_CITY_ID,
            is_agree: false,
            otp_code: '',
            nationality_id: '',
            birth_date: '',
            birth_date_day: '',
            birth_date_month: '',
            birth_date_year: '',
            method_delivery: '',
            prefer_working_days: '',
            prefer_working_hours: '',
        },
        submitting: false,
        validation: {} as FormValidation<IFormsValues>,
        errors: [] as FormError[],
        countries: [] as ICountry[],
        showRules: false,
    };

    private tm: number = null;
    private formPersonalData: FormInstance = null;
    private sentAnalyticEvents = new Map<AnalyticEvents, boolean>();
    private monthInputRef = createRef<Input>();
    private yearInputRef = createRef<Input>();

    constructor(props: IProps) {
        super(props);

        this.onFormChange = this.onFormChange.bind(this);
        this.changeScreen = this.changeScreen.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);

        this.tick = this.tick.bind(this);

        this.createApplicationAndSendCode = this.createApplicationAndSendCode.bind(this);
        this.verifyPhoneNumber = this.verifyPhoneNumber.bind(this);
        this.updatePassport = this.updatePassport.bind(this);
        this.updateApplication = this.updateApplication.bind(this);

        this.sendAnalyticEvent = this.sendAnalyticEvent.bind(this);
        this.sendAnalyticEventScreenPersonalData = this.sendAnalyticEventScreenPersonalData.bind(this);
        this.checkAddBirthDate = this.checkAddBirthDate.bind(this);
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const referralValue = queryParams.get(REFERRAL_KEY);
        if (referralValue) {
            localStorageSet(REFERRAL_KEY, referralValue);
        }
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
              this.onFormChange({user_ip: data.ip })
            })
            .catch(error => {
              console.error('Error fetching IP:', error);
            });
    }

    componentDidUpdate() {
        const {
            currentScreen,
            errors,
            validation,
        } = this.state;

        if (validation?.phone_number) {
            this.sendAnalyticEvent(AnalyticEvents.AddWrongNumber);
        }

        if (errors.includes(__dataSources('reachAgeError'))) {
            this.sendAnalyticEvent(AnalyticEvents.GetMinorWarning);
        }

        if (currentScreen === Screens.Success) {
            this.sendAnalyticEvent(AnalyticEvents.SuccessScreenCouriersForm);
        }
    }

    render() {
        const {
            currentScreen,
            showRules,
        } = this.state;
        const availableSteps = [
            Screens.PersonalData,
            Screens.Citizenship,
            Screens.WorkingConditions,
        ];
        const showSteps = availableSteps.includes(currentScreen);
        const showBackButton = isWebView();
        const backIconProps: any = {}; // https://github.com/ant-design/ant-design-icons/issues/354

        return (
            <div className={b()}>
                {!showRules && (
                    <header className={b('header')}>
                        <div className={b('header-title-wrapper')}>
                            {showBackButton && (
                                <Button
                                    size={'large'}
                                    className={b('icon-back')}
                                    icon={<LeftOutlined {...backIconProps} />}
                                    onClick={() => window.location.href = GO_TO_APP_URL}
                                />
                            )}
                            <h1 className={b('header-title')}>
                                {__(`header.title.${currentScreen}`)}
                            </h1>
                        </div>
                        {showSteps && (
                            <div className={b('steps')}>
                                {availableSteps.map((step, i) => ([
                                    <div
                                        className={b('steps-item', { active: Number(step) === currentScreen })}
                                        key={step}
                                    >
                                        <div className={b('steps-item-bullet')}>{i + 1}</div>
                                        <div className={b('steps-item-text')}>{__(`header.steps.${step}`)}</div>
                                    </div>,
                                    (availableSteps.length - 1 !== i) && (
                                        <div
                                            className={b('steps-item-divider')}
                                            key={`${step}-divider`}
                                        />
                                    ),
                                ]))}
                            </div>
                        )}
                    </header>
                )}
                {(currentScreen === Screens.PersonalData && !showRules) && this.renderScreenPersonalData()}
                {(currentScreen === Screens.PersonalData && showRules) && this.renderPrivacyPolicy()}
                {currentScreen === Screens.PhoneConfirmation && this.renderScreenPhoneConfirmation()}
                {currentScreen === Screens.Citizenship && this.renderScreenCitizenship()}
                {currentScreen === Screens.WorkingConditions && this.renderScreenWorkingConditions()}
                {currentScreen === Screens.Success && this.renderScreenSuccess()}
            </div>
        );
    }

    private renderScreenPersonalData() {
        const {
            cities,
        } = this.props;
        const {
            formsValues,
            submitting,
        } = this.state;
        const {visible, captcha_key} = formsValues;

        const __screen = i18n(locales, `screens.${Screens.PersonalData}`);
        const formValues = {
            first_name: formsValues.first_name,
            last_name: formsValues.last_name,
            phone_number: formsValues.phone_number,
            city_id: formsValues.city_id,
            is_agree: formsValues.is_agree,
        };
        const submitDisabled = DEMO_ENABLED ? false : this.checkSubmitDisabled(formValues);
      
        const handleChallengeHidden = () => {
          return this.onFormChange({ visible: false });
        };
      
        const handleButtonClick = () => this.onFormChange({visible: true, captcha_key: captcha_key +1  });
        const onSuccess = (e:string) => {
          this.onFormChange({token: e, visible: false})
          this.onFormSubmit(Screens.PhoneConfirmation, formValues, this.createApplicationAndSendCode, [
                this.sendAnalyticEventScreenPersonalData,
            ])
        };

        const formProps = {
            name: 'PersonalData',
            initialValues: formValues,
            onValuesChange: this.onFormChange,
            onFinish: () => handleButtonClick(),
            ref: (form: FormInstance) => this.formPersonalData = form,
        };

        return (
            <Form
                {...formProps}
                layout={'vertical'}
            >
                <main className={b('main', {'limited-width': true})}>
                    <InvisibleSmartCaptcha
                      // test={true} //for test
                      key={captcha_key}
                      sitekey="ysc1_Qg5TYOEEUru0yRebdkX5RNRlbNxMCiMyrRV0RdMw481bd93b"
                      onSuccess={onSuccess}
                      onChallengeHidden={handleChallengeHidden}
                      visible={visible}
                    />
                    <Form.Item
                        label={__screen('form.first_name.label')}
                        name={'first_name'}
                        {...this.getFormValidationProps('first_name')}
                    >
                        <Input
                            autoFocus
                            onBlur = {() => {
                                if (isFieldValid('first_name', formValues.first_name)) {
                                    this.sendAnalyticEvent(AnalyticEvents.AddUserFirstName);
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={__screen('form.last_name.label')}
                        name={'last_name'}
                        {...this.getFormValidationProps('last_name')}
                    >
                        <Input
                            onBlur = {() => {
                                if (isFieldValid('last_name', formValues.last_name)) {
                                    this.sendAnalyticEvent(AnalyticEvents.AddUserLastName);
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={__screen('form.phone_number.label')}
                        name="phone_number"
                        {...this.getFormValidationProps('phone_number')}
                    >
                        <InputNumber
                            initialValue={formValues.phone_number}
                            prefix={PHONE_NUMBER_PREFIX}
                            maxLength={PHONE_NUMBER_INPUT_MAX_LENGTH}
                            onChangeValue={(value) => {
                                const formValue = {
                                    phone_number: value,
                                };
                                // this method doesn't call a FormInstance.onValuesChange
                                this.formPersonalData.setFieldsValue(formValue);
                                // set number manually
                                this.onFormChange(formValue);
                            }}
                            onBlur = {() => {
                                if (isFieldValid('phone_number', formValues.phone_number)) {
                                    this.sendAnalyticEvent(AnalyticEvents.AddUserPhone);
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={__screen('form.city_id.label')}
                        name={'city_id'}
                        {...this.getFormValidationProps('city_id')}
                    >
                        <Select
                            placeholder={__screen('form.city_id.placeholder')}
                            onBlur = {() => {
                                if (isFieldValid('city_id', formValues.city_id)) {
                                    this.sendAnalyticEvent(AnalyticEvents.ChooseCityName);
                                }
                            }}
                        >
                            {cities.map(city => (
                                <Select.Option
                                    id={city.id}
                                    value={city.id}
                                    key={city.id}
                                >
                                    {city.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {this.renderFormErrors()}
                </main>
                <footer className={b('footer')}>
                    <div className={b('footer-item')}>
                        <Form.Item
                            name={'is_agree'}
                            valuePropName={'checked'}
                        >
                            <Checkbox
                                id={'agreement'}
                                className = { b('footer-agreement-checkbox') }
                            >
                                <label
                                    className = { b('footer-agreement-label') }
                                    htmlFor={'agreement'}
                                >
                                    {__screen('form.is_agree.text')}
                                </label>
                                {this.renderPrivacyPolicyLink()}
                            </Checkbox>
                        </Form.Item>
                    </div>
                    <div className={b('footer-item')}>
                        <Form.Item>
                            {this.renderSubmitButton({
                                disabled: submitDisabled,
                                loading: submitting,
                                children: __screen('buttons.submit'),
                            })}
                        </Form.Item>
                    </div>
                </footer>
            </Form>
        );
    }

    private changeFocus(changedValues: Partial<IFormsValues>) {
        const [
            field,
            value,
        ] = Object.entries(changedValues)[0];

        if (field === 'birth_date_day' && value.toString().length === 2) {
            this.monthInputRef.current.focus();
        }
        if (field === 'birth_date_month' && value.toString().length === 2) {
            this.yearInputRef.current.focus();
        }
    }

    private renderScreenPhoneConfirmation() {
        const {
            countdown,
            formsValues,
            submitting,
            validation,
        } = this.state;

        const {
            captcha_key,
            visible
        } = formsValues

        const __screen = i18n(locales, `screens.${Screens.PhoneConfirmation}`);
        const formValues = {
            phone: formsValues.phone_number,
            otp_code: formsValues.otp_code,
        };
        const retryAvailable = countdown <= 0;
        const submitDisabled = DEMO_ENABLED
            ? false
            : this.checkSubmitDisabled(formValues);
        const formProps = {
            name: 'PhoneConfirmation',
            initialValues: formValues,
            onValuesChange: this.onFormChange,
            // hack: pass current form values because of two forms
            onFinish: () => this.onFormSubmit(Screens.Citizenship, formValues, this.verifyPhoneNumber, [
                () => this.sendAnalyticEvent(AnalyticEvents.ConfirmNumber),
            ]),
        };
        const onClickRetry = () => {
          this.createApplicationAndSendCode();
          this.startTimer();
          this.sendAnalyticEvent(AnalyticEvents.GetNewCode);
        }
        const handleButtonClick = () => this.onFormChange({visible: true, captcha_key: captcha_key +1  });
        const onSuccess = (e:string) => {
          this.onFormChange({token: e, visible: false})
          onClickRetry()
        };

        return (
            <>
                <main className={b('main', {'no-image': true})}>
                  <InvisibleSmartCaptcha
                        // test={true} // for test
                        key={captcha_key}
                        sitekey="ysc1_Qg5TYOEEUru0yRebdkX5RNRlbNxMCiMyrRV0RdMw481bd93b"
                        onSuccess={onSuccess}
                        onChallengeHidden={handleButtonClick}
                        visible={visible}
                      />
                    <Result
                        icon={(
                            <Image
                                preview={false}
                                src={'/i/phone_confirmation.svg'}
                            />
                        )}
                        extra={(
                            <>
                                <div
                                    className={b('verification-notice')}
                                    dangerouslySetInnerHTML={{
                                        __html: __screen('title', {
                                            number: formsValues.phone_number,
                                        }),
                                    }}
                                />
                                <div className={b('verification-form')}>
                                    <div className={b('verification-form-code-wrapper')}>
                                        <ReactCodeInput
                                            autoFocus
                                            fields={4}
                                            onComplete={() => this.sendAnalyticEvent(AnalyticEvents.AddConfirmCode)}
                                            onChange={(code: string) => this.onFormChange({ otp_code: code })}
                                            className={b('verification-code', {error: validation.otp_code})}
                                        />
                                    </div>
                                    <div className={b('verification-form-actions')}>
                                        {retryAvailable && (
                                            <Button
                                                type={'link'}
                                                onClick={handleButtonClick}
                                            >
                                                {__screen('retry')}
                                            </Button>
                                        )}
                                        {validation.otp_code && (
                                            <>
                                                <Typography.Text type={'danger'}>
                                                    {__screen('invalidCode')}
                                                </Typography.Text>
                                            </>
                                        )}
                                        {!retryAvailable && (
                                            <Typography.Text type={'secondary'}>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: __screen('retryAfter', {
                                                            countdown: String(countdown),
                                                        }, countdown),
                                                    }}
                                                />
                                            </Typography.Text>
                                        )}
                                    </div>
                                </div>
                                {this.renderFormErrors()}
                            </>
                        )}
                    />
                </main>
                <footer className={b('footer')}>
                    <Form {...formProps} layout={'vertical'}>
                        <div className={b('footer-item')}>
                            {this.renderBackButton(Screens.PersonalData, {
                                children: __screen('buttons.goBack'),
                                onClick: () => {
                                    this.onFormChange({ otp_code: '' })
                                    this.changeScreen(Screens.PersonalData)
                                }
                            })}
                        </div>
                        <div className={b('footer-item')}>
                            {this.renderSubmitButton({
                                disabled: submitDisabled,
                                loading: submitting,
                                children: __screen('buttons.submit'),
                            })}
                        </div>
                    </Form>
                </footer>
            </>
        );
    }

    private renderScreenCitizenship() {
        const {
            countries,
            errors,
            formsValues,
            submitting,
        } = this.state;

        const __screen = i18n(locales, `screens.${Screens.Citizenship}`);
        const formValues = {
            nationality_id: formsValues.nationality_id || undefined,
            birth_date_day: formsValues.birth_date_day,
            birth_date_month: formsValues.birth_date_month,
            birth_date_year: formsValues.birth_date_year,
        };
        const submitDisabled = this.checkSubmitDisabled(formValues);
        const formProps = {
            name: 'Citizenship',
            initialValues: {
                nationality_id: formValues.nationality_id,
                birth_date_day: formValues.birth_date_day,
                birth_date_month: formValues.birth_date_month,
                birth_date_year: formValues.birth_date_year,
            },
            onValuesChange: (changedValues: Partial<IFormsValues>) => {
                this.changeFocus(changedValues);
                this.onFormChange(changedValues);
            },
            // hack: pass current form values because of two forms
            onFinish: () => this.onFormSubmit(Screens.WorkingConditions, formValues, this.updatePassport, [
                () => this.sendAnalyticEvent(AnalyticEvents.SecondScreenDataSent),
            ]),
        };

        return (
            <>
                <main className={b('main', {'limited-width': true})}>
                    <Form {...formProps} layout={'vertical'}>
                        <Form.Item
                            label={__screen('form.nationality_id.label')}
                            name={'nationality_id'}
                        >
                            <Select
                                placeholder={__screen('form.nationality_id.placeholder')}
                                onBlur = {() => {
                                    if (formValues.nationality_id){
                                        this.sendAnalyticEvent(AnalyticEvents.ChooseNationality);
                                    }
                                }}
                            >
                                {countries.map(country => (
                                    <Select.Option
                                        id={country.id}
                                        value={country.id}
                                        key={country.id}
                                    >
                                        {country.title}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <div className={b('birthday')}>
                            <div className={b('birthday-label')}>
                                {__screen('form.birth_date.label')}
                            </div>
                            <div
                                className={b('birthday-items')}
                                onBlur = {this.checkAddBirthDate}
                            >
                                <div className={b('birthday-item', {type: 'day'})}>
                                    <Form.Item
                                        label={__screen('form.birth_date.day.label')}
                                        name={'birth_date_day'}
                                    >
                                        <Input
                                            maxLength={BIRTH_DAY_INPUT_MAX_LENGTH}
                                        />
                                    </Form.Item>
                                </div>
                                <div className={b('birthday-item', {type: 'month'})}>
                                    <Form.Item
                                        label={__screen('form.birth_date.month.label')}
                                        name={'birth_date_month'}
                                    >
                                        <Input
                                            ref={this.monthInputRef}
                                            maxLength={BIRTH_MONTH_INPUT_MAX_LENGTH}
                                        />
                                    </Form.Item>
                                </div>
                                <div className={b('birthday-item', {type: 'year'})}>
                                    <Form.Item
                                        label={__screen('form.birth_date.year.label')}
                                        name={'birth_date_year'}
                                    >
                                        <Input
                                            className={b('input', {
                                                error: errors.includes(__dataSources('error.tooYoung')),
                                            })}
                                            ref={this.yearInputRef}
                                            maxLength={BIRTH_YEAR_INPUT_MAX_LENGTH}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        {this.renderFormErrors()}
                    </Form>
                </main>
                <footer className={b('footer')}>
                    <Form {...formProps} layout={'vertical'}>
                        <div className={b('footer-item')}>
                            {this.renderBackButton(Screens.PersonalData, {
                                children: __screen('buttons.goBack'),
                            })}
                        </div>
                        <div className={b('footer-item')}>
                            {this.renderSubmitButton({
                                disabled: submitDisabled,
                                loading: submitting,
                                children: __screen('buttons.submit'),
                            })}
                        </div>
                    </Form>
                </footer>
            </>
        );
    }

    private renderScreenWorkingConditions() {
        const {
            formsValues,
            submitting,
        } = this.state;

        const __screen = i18n(locales, `screens.${Screens.WorkingConditions}`);
        const formValues = {
            method_delivery: formsValues.method_delivery,
            prefer_working_days: formsValues.prefer_working_days,
            prefer_working_hours: formsValues.prefer_working_hours,
        };
        const submitDisabled = this.checkSubmitDisabled(formValues);
        const formProps = {
            name: 'WorkingConditions',
            initialValues: formValues,
            onValuesChange: this.onFormChange,
            // hack: pass current form values because of two forms
            onFinish: () => this.onFormSubmit(Screens.Success, formValues, this.updateApplication, [
                () => this.sendAnalyticEvent(AnalyticEvents.SendFormButton),
            ]),
            onBlur: () => {
                formsValues.prefer_working_days && this.sendAnalyticEvent(AnalyticEvents.ChooseWorkingDays);
                formsValues.prefer_working_hours && this.sendAnalyticEvent(AnalyticEvents.ChooseWorkingType);
                formsValues.method_delivery && this.sendAnalyticEvent(AnalyticEvents.ChooseDeliveryMethod);
            },
        };

        return (
            <>
                <main className={b('main')}>
                    <Form {...formProps} layout={'vertical'}>
                        <Form.Item
                            label={__screen('form.prefer_working_days.label')}
                            name={'prefer_working_days'}
                        >
                            <Radio.Group>
                                <Space direction={'vertical'}>
                                    {Object.values(PreferWorkingDaysOption).map((option: PreferWorkingDaysOption) => (
                                        <Radio
                                            value={option}
                                            key={option}
                                        >
                                            {__screen(`form.prefer_working_days.options.${option}`)}
                                        </Radio>
                                    ))}
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label={__screen('form.prefer_working_hours.label')}
                            name={'prefer_working_hours'}
                        >
                            <Radio.Group>
                                <Space direction={'vertical'}>
                                    {Object.values(PreferWorkingHoursOption).map((option: PreferWorkingHoursOption) => (
                                        <Radio
                                            value={option}
                                            key={option}
                                        >
                                            {__screen(`form.prefer_working_hours.options.${option}`)}
                                        </Radio>
                                    ))}
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label={__screen('form.method_delivery.label')}
                            name={'method_delivery'}
                        >
                            <Radio.Group>
                                <Space direction={'vertical'}>
                                    {Object.values(MethodDeliveryOption).map((option: MethodDeliveryOption) => (
                                        <Radio
                                            value={option}
                                            key={option}
                                        >
                                            {__screen(`form.method_delivery.options.${option}`)}
                                        </Radio>
                                    ))}
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                </main>
                <footer className={b('footer')}>
                    <Form {...formProps} layout={'vertical'}>
                        <div className={b('footer-item')}>
                            {this.renderBackButton(Screens.Citizenship, {
                                children: __screen('buttons.goBack'),
                            })}
                        </div>
                        <div className={b('footer-item')}>
                            {this.renderSubmitButton({
                                disabled: submitDisabled,
                                loading: submitting,
                                children: __screen('buttons.submit'),
                            })}
                        </div>
                    </Form>
                </footer>
            </>
        );
    }

    private renderScreenSuccess() {
        const __screen = i18n(locales, `screens.${Screens.Success}`);
        const webView = isWebView();
        const linkText = webView
            ? __screen('backToApp')
            : __screen('backLink');
        const href = webView
            ? GO_TO_APP_URL
            : MAIN_SITE_URL;

        return (
            <main className={b('main')}>
                <Result
                    icon={(
                        <Image
                            preview={false}
                            src={'/i/success.svg'}
                        />
                    )}
                    extra={(
                        <>
                            <p>{__screen('subTitle')}</p>
                            <p>
                                <Button
                                    type={'link'}
                                    href={href}
                                >
                                    {linkText}
                                </Button>
                            </p>
                        </>
                    )}
                />
            </main>
        );
    }

    private renderBackButton(screen: Screens, buttonProps: ButtonProps) {
        return (
            <Button
                shape={'round'}
                size={'large'}
                onClick={() => this.changeScreen(screen)}
                {...buttonProps}
            />
        );
    }

    private renderSubmitButton(buttonProps: ButtonProps) {
        return (
            <Button
                danger
                shape={'round'}
                size={'large'}
                type={'primary'}
                htmlType={'submit'}
                {...buttonProps}
            />
        );
    }

    private renderFormErrors() {
        const {
            errors,
        } = this.state;

        if (!(Array.isArray(errors) && errors.length > 0)) {
            return null;
        }

        return (
            <div className={b('errors')}>
                {errors.map(error => (
                    <p key={error}>
                        <Typography.Text type={'danger'}>{error}</Typography.Text>
                    </p>
                ))}
            </div>
        );
    }

    /**
     * Update current form fields values
     */
    private onFormChange(changedValues: Partial<IFormsValues>) {
        this.setState(state => ({
            formsValues: {
                ...state.formsValues,
                ...changedValues,
            },
            errors: [],
            validation: {} as FormValidation<IFormsValues>,
        }));
    }

    /**
     * Update form values for screen
     * Set submitting form mode
     * Call specific screen method
     * Change screen. Otherwise - show form validation errors
     */
    private onFormSubmit(
        nextScreen: Screens,
        finalFormValues: Partial<IFormsValues>,
        callback: () => Promise<IFormResponse<any>>,
        yaMetrikaCallbacks: Array<() => void>,
    ) {
        this.setState(state => ({
            formsValues: {
                ...state.formsValues,
                ...finalFormValues,
            },
        }), async () => {
            try {
                this.setState({
                    submitting: true,
                });

                if (!DEMO_ENABLED) {
                    const {
                        ok,
                        validation,
                        errors,
                    } = await callback();

                    if (!ok) {
                        return this.setState({
                            submitting: false,
                            validation,
                            errors,
                        });
                    }
                }

                yaMetrikaCallbacks.forEach(callback => callback());
                this.changeScreen(nextScreen);
            } catch (ex) {
                this.setState({
                    submitting: false,
                });
            }
        });
    }

    private changeScreen(nextScreen: Screens) {
        this.setState(() => {
            if (nextScreen === Screens.PhoneConfirmation) {
                // Set interval only when success change screen
                this.startTimer();
            } else {
                // Should clear interval if leaving phone confirmation screen
                clearInterval(this.tm);
            }

            return {
                currentScreen: nextScreen,
                submitting: false,
                validation: {} as any,
                errors: [],
            };
        });
    }

    private getFormValidationProps(field: keyof IFormsValues): {
        hasFeedback?: boolean;
        validateStatus?: 'error';
        help?: string;
    } {
        const {
            validation,
        } = this.state;
        const isInvalidField = field in validation;

        if (isInvalidField) {
            return {
                hasFeedback: false,
                validateStatus: 'error',
                help: validation[field] || '',
            };
        }

        return {};
    }

    private async createApplicationAndSendCode() {
        const {
            formsValues,
        } = this.state;

        const response = await createApplication({
            first_name: formsValues.first_name,
            last_name: formsValues.last_name,
            phone_number: formsValues.phone_number,
            city_id: formsValues.city_id,
            is_agree: formsValues.is_agree,
            user_ip: formsValues.user_ip,
            yandex_captcha_token: formsValues.token,
        });

        if (!response.ok) {
            this.sendAnalyticEvent(AnalyticEvents.NumberAlreadyExists);
        }

        return response;
    }

    private async verifyPhoneNumber() {
        const {
            formsValues,
        } = this.state;

        const response = await verifyPhone({
            phone: formsValues.phone_number,
            otp_code: formsValues.otp_code,
            token: formsValues.token
        });

        if (response.ok) {
            const userId = response.data.user.id;

            localStorageSet(USER_ID_KEY, userId);
            this.sendCPA(userId);

            const countries = await getCountries();
            this.setState({
                countries,
            });
        } else {
            this.sendAnalyticEvent(AnalyticEvents.AddIncorrectCode);
            this.sendAnalyticEvent(AnalyticEvents.GetWrongCode);
        }

        return response;
    }

    private updatePassport() {
        const {
            formsValues,
        } = this.state;

        return updatePassport({
            is_agree: formsValues.is_agree,
            nationality_id: formsValues.nationality_id,
            birth_date_day: formsValues.birth_date_day,
            birth_date_month: formsValues.birth_date_month,
            birth_date_year: formsValues.birth_date_year,
        });
    }

    private updateApplication() {
        const {
            formsValues,
        } = this.state;

        return updateApplication({
            method_delivery: formsValues.method_delivery,
            prefer_working_days: formsValues.prefer_working_days,
            prefer_working_hours: formsValues.prefer_working_hours,
        });
    }

    private tick() {
        this.setState(prevState => ({
            countdown: prevState.countdown - 1,
        }), () => {
            if (this.state.countdown < 1) {
                clearInterval(this.tm);
            }
        });
    }

    private sendAnalyticEvent(
        event: AnalyticEvents,
        additionalParams: CustomParams = {},
    ) {
        if (this.sentAnalyticEvents.has(event)) {
            return;
        }

        if (AddUserIdInEvent.includes(event)) {
            additionalParams[USER_ID_KEY] = localStorageGet(USER_ID_KEY)
        }

        this.sentAnalyticEvents.set(event, true);
        sendYandex(event);
        sendGoogle(event, additionalParams);
    }

    private sendAnalyticEventScreenPersonalData() {
        if (!this.sentAnalyticEvents.has(AnalyticEvents.ChooseCityName)) {
            this.sendAnalyticEvent(AnalyticEvents.ChooseCityName);
        }
        this.sendAnalyticEvent(AnalyticEvents.FirstScreenDataSend);
    }

    private checkAddBirthDate() {
        if (isDateCorrect(this.state.formsValues)) {
            this.sendAnalyticEvent(AnalyticEvents.AddBirthDate);
        }
    }

    private checkSubmitDisabled(formValues: Partial<IFormsValues>) {
        const {
            errors,
            validation,
        } = this.state;

        return  errors.length > 0
            || Boolean(Object.keys(validation).length)
            || !isFormValid(formValues);
    }

    // send Cost Per Action event
    private sendCPA(userId: string) {
        const queryParams = new URLSearchParams(window.location.search);
        const condition = userId && (queryParams.get(CPA_KEY) === CPA_VALUE);

        if (!condition) {
            return;
        }

        const img = document.createElement('img');
        img.src = `${CPA_URL}${userId}`;
        img.width = 1;
        img.height = 1;

        document.body.appendChild(img);
    }

    private renderPrivacyPolicy() {
        return (
            <PrivacyPolicy
                onReturnBack={() => this.setState({showRules: false})}
            />
        )
    }

    private renderPrivacyPolicyLink() {
        const __screen = i18n(locales, `screens.${Screens.PersonalData}`);

        if (isWebView()) {
            return (
                <Typography.Link onClick={() => this.setState({showRules: true})}>
                    {__screen('form.is_agree.link')}
                </Typography.Link>
            )
        }

        return (
            <Link
                to={PRIVACY_POLICY_ROUTE}
                target='_blank'
            >
                {__screen('form.is_agree.link')}
            </Link>
        )
    }

    private startTimer() {
        this.setState({
            countdown: COUNTDOWN_DEFAULT_VALUE_SEC,
        }, () => {
            this.tm = window.setInterval(this.tick, COUNTDOWN_TICK_MS);
        });
    }

}
