import {
    AvailableLocales,
    detectLocale,
} from 'src/libs/locale';

const {
    API_VERSION,
    API_URL,
    DEFAULT_LOCALE,
    DEFAULT_CITY_ID: DEFAULT_CITY_ID_ENV,
} = process.env;

export const BACKEND_URL = `${API_URL}/${API_VERSION}`;
export const DEFAULT_CITY_ID = parseInt(DEFAULT_CITY_ID_ENV) || null;
export const MAIN_SITE_URL = 'https://masterdelivery.ru';
export const SENTRY_DSN = 'https://ed3f651535f44153b5d08e714dbbbc84@sentry.md-dev.ru/20';
export const LOCALE = detectLocale() || DEFAULT_LOCALE as AvailableLocales;
// TODO: lib localstorage
export const REFERRAL_KEY = 'referral'; // same value used in Tilda
export const USER_ID_KEY = 'user_id';

// https://masterdelivery.atlassian.net/browse/LSS-215
export const UTM_SOURCE_KEY = 'utm_source';
export const UTM_SOURCE_WEBVIEW = [
    'android_app',
    'ios_app',
];
export const PRIVACY_POLICY_ROUTE = '/privacy';

// https://metrika.yandex.ru/dashboard?group=day&period=quarter&id=82206874
// https://yandex.ru/support/metrica/code/counter-initialize.html
export const YA_METRIKA = {
    accounts: [
        82206874,
    ],
    options: {
        webvisor: true,
    },
    version: '2' as any,
};

export enum AnalyticEvents {
    // renderScreenPersonalData
    AddUserPhone = 'add_user_phone',                // Пользователь успешно ввел номер телефона
    AddUserFirstName = 'add_user_first_name',       // Пользователь успешно ввел имя
    AddUserLastName = 'add_user_last_name',         // Пользователь успешно ввел фамилию
    AddWrongNumber = 'add_wrong_number',            // Номер введен некорректно
    ChooseCityName = 'choose_city_name',            // Пользователь выбрал один из городов в списке
    NumberAlreadyExists = 'number_already_exists',  // Анкета с этим номером уже отправлена
    FirstScreenDataSend = 'first_screen_data_send', // Данные с первой страницы анкеты заполнены и отправлены

    // renderScreenPhoneConfirmation
    AddConfirmCode = 'add_confirm_code',     // Пользователь ввел корректный код подтверждения
    AddIncorrectCode = 'add_incorrect_code', // Пользователь ввел некорректный код
    GetWrongCode = 'get_wrong_code',         // Выведен текст: введен некорректный код
    GetNewCode = 'get_new_code',             // Пользователь выбрал “Отправить код повторно”
    ConfirmNumber = 'confirm_number',        // Пользователь выбрал “дальше” на экране “Подтвердите номер телефона” и код введен корректно

    // renderScreenCitizenship
    ChooseNationality = 'choose_nationality',         // Пользователь выбрал Гражданство
    AddBirthDate = 'add_birth_date',                  // Пользователь успешно ввел дату рождения
    GetMinorWarning = 'get_minor_warning',            // Получено сообщение “Мы принимаем на работу только курьеров в возрасте от 18 и старше”
    SecondScreenDataSent = 'second_screen_data_sent', // Пользователь выбрал “дальше” и данные из раздела “гражданство” отправлены

    // renderScreenWorkingConditions
    ChooseWorkingDays = 'choose_working_days',       // Пользователь выбрал число дней в неделю, в которые он желает работать
    ChooseWorkingType = 'choose_working_type',       // Пользователь выбрал тип работы, который ему подходит
    ChooseDeliveryMethod = 'choose_delivery_method', // Пользователь выбрал тип транспорта
    SendFormButton = 'send_form_button',             // Пользователь выбрал “отправить анкету”

    //renderScreenSuccess
    SuccessScreenCouriersForm = 'success_screen_couriers_form', // Экран успеха: Анкета отправлена
}

// Cost Per Action: https://masterdelivery.atlassian.net/browse/LSS-323
export const CPA_KEY = 'cpa';
export const CPA_VALUE = '4060';
export const CPA_URL = `https://go.cpaex.ru/track/img?goal_id=${CPA_VALUE}&track_id=`;

export const AddUserIdInEvent = [
    AnalyticEvents.AddBirthDate,
    AnalyticEvents.AddConfirmCode,
    AnalyticEvents.ChooseDeliveryMethod,
    AnalyticEvents.ChooseNationality,
    AnalyticEvents.ChooseWorkingDays,
    AnalyticEvents.ChooseWorkingType,
    AnalyticEvents.ConfirmNumber,
    AnalyticEvents.GetMinorWarning,
    AnalyticEvents.SecondScreenDataSent,
    AnalyticEvents.SendFormButton,
    AnalyticEvents.SuccessScreenCouriersForm,
];
